import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isWidthUp } from '@material-ui/core/withWidth';

import Title from '../../components/Title';

import CellWithData from './CellWithData';

Products.propTypes = {
  columnClasses: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  targets: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
};

export default function Products({
  columnClasses,
  data,
  group,
  history,
  products,
  targets,
  width,
}) {
  const classes = useStyles();
  return (
    <>
      <Hidden mdDown>
        <Grid container item xs={12}>
          <Grid classes={{ item: classes.product }} item xs />
          <Grid className="table-step-02" container item xs>
            {targets.map(({ title, id }) => (
              <Grid
                classes={{
                  item: classNames(columnClasses, classes.header),
                }}
                item
                key={id}
                lg
              >
                <Typography
                  classes={{ h6: classes.targetTitle }}
                  color="inherit"
                  variant="h6"
                >
                  {title.raw}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Hidden>
      {products.map(({ id, name, subtitle, color, logo }, index) => (
        <Grid
          className="table-step-01"
          classes={{ container: classes.row }}
          container
          item
          key={id}
          xs={12}
        >
          <Grid classes={{ item: classes.product }} item lg md={12}>
            <Title
              colorScheme={color}
              logo={logo}
              name={name}
              subtitle={subtitle}
            />
          </Grid>
          <Grid classes={{ item: classes.cells }} container item lg md={12}>
            {targets.map(({ title, id: targetId }) => {
              const cellData = data[`${id}-${targetId}`];
              return (
                <Hidden key={`${id}.${targetId}`} mdDown={!cellData}>
                  <Grid
                    classes={{
                      item: classNames(
                        columnClasses,
                        index === products.length - 1 && classes.footer,
                      ),
                    }}
                    item
                    lg
                    md={6}
                    xs={12}
                  >
                    <CellWithData
                      data={cellData}
                      group={group}
                      history={history}
                      productColor={color}
                      productId={id}
                      productSubtitle={subtitle}
                      square={!isWidthUp('sm', width)}
                      targetId={targetId}
                      targetTitle={title}
                      width={width}
                    />
                  </Grid>
                </Hidden>
              );
            })}
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    header: {
      borderRadius: '3px 3px 0 0',
      paddingBottom: 16,
    },
    product: {
      height: 160 + 72,
      marginRight: 0,
      paddingTop: 64,
      flex: '0 0 256px',
      [breakpoints.up('lg')]: {
        marginRight: 8,
        paddingTop: 24,
        height: '100%',
      },
      [breakpoints.up('xl')]: {
        paddingTop: 32,
        marginRight: 16,
      },
    },
    cells: {
      [breakpoints.up('lg')]: {
        height: '100%',
      },
    },
    targetTitle: {
      fontWeight: 400,
    },
    row: {
      height: 'auto',
      [breakpoints.up('lg')]: {
        height: 288,
      },
    },
    footer: {
      borderRadius: '0 0 3px 3px',
    },
  }),
  { name: 'Products' },
);
