import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  Menu,
  MenuItem,
  ListItemIcon,
  Button,
} from '@material-ui/core';

import withWidth from '@material-ui/core/withWidth';
import {
  compose,
  palette,
  spacing,
  positions,
  shadows,
  style,
} from '@material-ui/system';
import { makeStyles, styled } from '@material-ui/styles';
import { MoreVert, Public, Info } from '@material-ui/icons';

import oldLogo from '../assets/logoCRPM-old.png';

// import Logo from './Logo';
import GoBackButton from './GoBackButton';

const transitions = style({
  prop: 'transition',
  transform: property => `${property} 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s`,
});

const StyledAppBar = styled(AppBar)(
  compose(
    palette,
    spacing,
    shadows,
    positions,
    transitions,
  ),
);

function Header({
  shadows,
  backButton,
  basePath,
  history,
  onStartTour,
  reset,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <StyledAppBar
      bgcolor="#F0F3F5 !important"
      boxShadow={shadows}
      color="#2A4F62 !important"
      position="relative"
      py={4}
      transition="box-shadow"
      zIndex={1}
    >
      <Toolbar>
        <Grid alignItems="center" container item wrap="nowrap" xs>
          {/* <Logo size={48} /> */}
          <img
            alt="Logo"
            className={classes.oldLogo}
            onClick={() => history.push('/')}
            src={oldLogo}
          />
          <Typography
            classes={{ h5: classes.title }}
            color="inherit"
            variant="h5"
          >
            {'Catalogue des formations'}
          </Typography>
          {/* <Grid
            alignItems="center"
            classes={{ container: classes.title }}
            container
            direction={isWidthUp('sm', width) ? 'row' : 'column'}
            item
          >
            <Typography classes={{ h5: classes.logoText }} variant="h5">
              {'crpm'}
            </Typography> 
            
          </Grid>*/}
        </Grid>
        <Hidden xsDown>
          <Grid
            classes={{ item: classes.icons }}
            container
            item
            justify="flex-end"
            wrap="nowrap"
            xs
          >
            <GoBackButton
              active={backButton}
              basePath={basePath}
              reset={reset}
              variant="icon"
            />
            <Button href="https://www.crpm.ch" variant="text">
              <Public className={classes.leftIconButton} />
              <span className={classes.labelButton}>
                {'Vister le site web'}
              </span>
            </Button>
            <Button
              disabled={backButton}
              onClick={() => onStartTour(true)}
              variant="text"
            >
              <Info className={classes.leftIconButton} />
              <span className={classes.labelButton}>
                {'Visualiser le guide'}
              </span>
            </Button>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} onClose={handleCloseMenu} open={!!anchorEl}>
            <GoBackButton
              active={backButton}
              basePath={basePath}
              onClick={handleCloseMenu}
              reset={reset}
              variant="menu"
            />
            <MenuItem
              component="a"
              href="https://www.crpm.ch"
              onClick={handleCloseMenu}
            >
              <ListItemIcon>
                <Public />
              </ListItemIcon>
              <Typography variant="inherit">{'Vister le site web'}</Typography>
            </MenuItem>
            <MenuItem disabled={backButton} onClick={() => onStartTour(true)}>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <Typography variant="inherit">{'Visualiser le guide'}</Typography>
            </MenuItem>
          </Menu>
        </Hidden>
      </Toolbar>
    </StyledAppBar>
  );
}

Header.propTypes = {
  backButton: PropTypes.bool.isRequired,
  basePath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onStartTour: PropTypes.func,
  reset: PropTypes.func.isRequired,
  shadows: PropTypes.number.isRequired,
};

Header.defaultProps = {
  onStartTour: () =>
    console.warn('[Header]: onStartTour handler is not implemented'),
};

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  appBar: {
    color: '#2A4F62',
    backgroundColor: '#F0F3F5',
    padding: '32px 0',
    zIndex: 1,
    boxShadow: 'none',
  },
  container: {
    maxWidth: 'none',
    flexWrap: 'wrap',
    [breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  title: {
    marginLeft: 8,
    fontSize: 24,
    [breakpoints.up('md')]: {
      marginLeft: 16,
      fontSize: 32,
    },
  },
  oldLogo: {
    width: 73,
    height: 64,
    cursor: 'pointer',
  },
  logoText: {
    fontFamily: 'CenturyGothic',
    color: 'inherit',
    marginTop: -8,
    marginRight: 32,
    fontSize: 32,
    [breakpoints.up('md')]: {
      fontSize: 40,
      marginRight: 64,
    },
  },
  icons: {
    flexGrow: 1,
    marginTop: 32,
    [breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  leftIconButton: {
    marginRight: spacing(1),
  },
  labelButton: {
    lineHeight: 'normal',
  },
}));

export default withWidth()(Header);
