import React from 'react';
import PropTypes from 'prop-types';

import { Query } from 'react-apollo';

import Loading from '../../components/Loading';

DataProvider.propTypes = {
  children: PropTypes.func.isRequired,
  dataKey: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
};

export default function DataProvider({ children, query, dataKey }) {
  return (
    <Query query={query}>
      {queryResult => (
        <Loading id={'trainings'} queryResult={queryResult}>
          {({ data = {} }) => children(data[dataKey])}
        </Loading>
      )}
    </Query>
  );
}
