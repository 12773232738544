import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';

import { useTheme } from '@material-ui/styles';

import tableSteps from './tableSteps';
import detailsSteps from './detailsSteps';

WalkThrough.propTypes = {
  children: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default function WalkThrough({ children, location }) {
  const [running, run] = useState(false);
  const [stepDef, setStepDef] = useState();
  const [step, setStep] = useState(0);
  const { palette } = useTheme();

  useEffect(() => {
    if (location.pathname === '/' && !localStorage.getItem('walkThrough')) {
      setStepDef(tableSteps);
      setStep(0);
      run(true);
    }
  }, []);

  useEffect(
    () => {
      if (location.pathname === '/') {
        setStepDef(tableSteps);
        setStep(0);
      } else {
        setStepDef(detailsSteps);
        setStep(0);
      }
    },
    [location],
  );

  return (
    <>
      <Joyride
        callback={({ action, index, type }) => {
          if (type === EVENTS.TOUR_END) {
            localStorage.setItem('walkThrough', true);
            run(false);
            setStep(0);
          } else if (
            [EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(
              type,
            )
          ) {
            setStep(index + (action === ACTIONS.PREV ? -1 : 1));
          }
        }}
        continuous
        locale={{
          back: 'Précédent',
          close: 'Fermer',
          last: 'Fermer',
          next: 'Suivant',
          skip: 'Passer',
        }}
        run={running}
        showSkipButton
        spotlightPadding={8}
        stepIndex={step}
        steps={stepDef}
        styles={{
          options: {
            primaryColor: palette.primary.main,
            textColor: palette.text.primary,
          },
          tooltip: {
            borderRadius: 3,
            padding: 16,
          },
          tooltipContainer: {
            textAlign: 'justify',
          },
          tooltipTitle: {
            padding: 0,
            textAlign: 'left',
          },
          tooltipContent: {
            padding: '16px 0 0 0',
          },
          tooltipFooter: {
            paddingTop: 16,
          },
          buttonNext: {
            borderRadius: 3,
            padding: '0 16px',
            height: 36,
            minWidth: 64,
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
          },
          buttonBack: {
            marginLeft: 'auto',
            marginRight: 16,
          },
          buttonClose: {
            padding: 16,
            display: 'none',
          },
        }}
      />
      {children({ running, run, step, setStepDef })}
    </>
  );
}
