import React from 'react';

export default [
  {
    id: 2,
    title: {
      raw: 'Contribuer, participer à',
      formatted: (
        <>
          {'Contribuer'}
          <br />
          {'participer à'}
        </>
      ),
    },
  },
  {
    id: 3,
    title: {
      raw: 'Accompagner, conduire des collaborateurs',
      formatted: (
        <>
          {'Accompagner'}
          <br />
          {'conduire des collaborateurs'}
        </>
      ),
    },
  },
  {
    id: 4,
    title: {
      raw: 'Accompagner, implémenter la stratégie',
      formatted: (
        <>
          {'Accompagner'}
          <br />
          {'implémenter la stratégie'}
        </>
      ),
    },
  },
  {
    id: 5,
    title: {
      raw: "Décider, piloter l'organisation",
      formatted: (
        <>
          {'Décider'}
          <br />
          {"piloter l'organisation"}
        </>
      ),
    },
  },
  {
    id: 6,
    title: {
      raw: "Administrer, gouverner l'organisation",
      formatted: (
        <>
          {'Administrer'}
          <br />
          {"gouverner l'organisation"}
        </>
      ),
    },
  },
];
