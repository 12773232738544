import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import shadows from '@material-ui/core/styles/shadows';
import transitions from '@material-ui/core/styles/transitions';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import createOverrides from './overrides';

const palette = {
  type: 'light',
  contrastThreshold: 2,
  primary: {
    main: '#4d7399',
  },
  secondary: {
    main: '#d6251c',
  },
  error: {
    main: '#d6251c',
  },
  text: {
    primary: '#3d5561',
    secondary: '#90a4ae',
    disabled: '#f4f8f9',
    hint: '#f4f8f9',
  },
  yellow: {
    text: '#777257',
    background: '#FFF289',
  },
  blue: {
    text: '#1C6997',
    background: '#9BCDEF',
  },
  purple: {
    text: '#6E3184',
    background: '#CFB9F3',
  },
  green: {
    text: '#2F7030',
    background: '#80D381',
  },
  greyBlue: {
    text: '#34467a',
    background: '#a9b3cf',
  },
};

const typography = {
  useNextVariants: true,
  fontFamily: 'AvenirNext',
  fontWeightLight: 200,
  fontWeightMedium: 600,
  fontWeightRegular: 400,
};

const breakpoints = createBreakpoints({});

export default createMuiTheme({
  palette,
  shadows,
  typography,
  transitions,
  breakpoints,
  overrides: createOverrides({
    palette,
    shadows,
    transitions,
    breakpoints,
  }),
});
