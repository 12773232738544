import React from 'react';
import { Typography } from '@material-ui/core';

export default [
  {
    content: (
      <Typography align="justify">
        {
          'Le CRPM repense continuellement son offre de formation et cherche à répondre toujours mieux, de façon simple et spécifique, à vos besoins de développement.'
        }
      </Typography>
    ),
    placement: 'center',
    disableBeacon: true,
    target: 'body',
    title: 'Bienvenue sur le catalogue de formations du CRPM',
  },
  {
    content: (
      <Typography>
        {
          'Le tableau met en lumière nos domaines d’expertises depuis plus de 40 ans ainsi que les défis et enjeux principaux que vous pouvez rencontrer, pour vous permettre de trouver rapidement et facilement une réponse à vos besoins.'
        }
      </Typography>
    ),
    title: 'Visuel',
    disableBeacon: true,
    target: '.table-step-01',
  },
  {
    content: (
      <Typography>
        {
          'Nous n’avons pas tous les mêmes besoins. Les défis et les enjeux d’un chef d’entreprise sont différents d’un chef d’équipe. Nos offres de formation répondent à VOS besoins.'
        }
      </Typography>
    ),
    title: 'Spécifique',
    disableBeacon: true,
    target: '.table-step-02',
  },
  {
    content: (
      <Typography>
        {
          'Cliquez dans une cellule pour visualiser tous les enjeux, les principaux défis et les formations proposées dans un domaine particulier.'
        }
      </Typography>
    ),
    title: 'Simple',
    disableBeacon: true,
    target: '.table-step-03',
  },
  {
    content: (
      <Typography>
        {
          "Recherchez dans l'ensemble de notre catalogue ou utilisez notre sélecteur de thème pour affiner les résultats."
        }
      </Typography>
    ),
    title: 'Pratique',
    disableBeacon: true,
    target: '.table-step-04',
  },
];
