import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import GridSection from './GridSection';

TrainingGrid.propTypes = {
  active: PropTypes.bool,
  onUpdateThemes: PropTypes.func.isRequired,
  productId: PropTypes.number,
  themes: PropTypes.array.isRequired,
  trainings: PropTypes.array,
};

TrainingGrid.defaultProps = {
  active: false,
  productId: -1,
  trainings: [],
};

export default function TrainingGrid({
  active,
  trainings,
  productId,
  themes,
  onUpdateThemes,
}) {
  const classes = useStyles();

  let main = [];
  let more = [];

  trainings.forEach(training => {
    if (
      training.themeDescriptors.some(({ level, theme }) => {
        return (
          level === '1' &&
          themes.some(({ active, id }) => active && theme.id === id)
        );
      })
    ) {
      main.push(training);
    } else {
      more.push(training);
    }
  }, []);

  return (
    active && (
      <Grid
        alignItems="flex-start"
        classes={{ container: classes.container }}
        container
        item
      >
        {main.length > 0 && (
          <GridSection
            activeThemes={themes.filter(({ active }) => active)}
            displayMeta
            onUpdateThemes={onUpdateThemes}
            productId={productId}
            themes={themes}
            trainings={main}
          />
        )}
        {more.length > 0 && (
          <GridSection displayTitle productId={productId} trainings={more} />
        )}
      </Grid>
    )
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    container: {
      backgroundColor: '#F0F3F5',
      position: 'absolute',
      zIndex: 10,
      padding: '0 16px 16px 16px',
      height: '100%',
      [breakpoints.up('md')]: {
        padding: '0 32px 32px 32px',
      },
    },
  }),
  { name: 'TrainingGrid' },
);
