import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { StaticData } from './';

DetailsTitle.propTypes = {
  productId: PropTypes.number.isRequired,
  targetId: PropTypes.number.isRequired,
};

export default function DetailsTitle({ productId, targetId }) {
  if (productId === -1 || targetId === -1) {
    return null;
  }

  const classes = useStyles();
  const { products, targets } = useContext(StaticData);
  const { subtitle } = products.find(({ id }) => id === productId);
  const { title } = targets.find(({ id }) => id === targetId);

  return (
    <>
      <Typography
        align="center"
        classes={{ h5: classes.targetTitle }}
        color="inherit"
        variant="h5"
      >
        {title.raw}
      </Typography>
      <Typography
        align="center"
        classes={{ h6: classes.h6 }}
        color="inherit"
        variant="h6"
      >
        {subtitle}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(
  {
    targetTitle: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 400,
    },
  },
  { name: 'DetailsTitle' },
);
