import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { makeStyles } from '@material-ui/styles';
import { Grid, RootRef } from '@material-ui/core';

import { BackdropCtx } from './Backdrop';

BackdropTitle.propTypes = {
  children: PropTypes.node,
};

BackdropTitle.defaultProps = {
  children: null,
};

export default function BackdropTitle({ children }) {
  const { setTitleHeight } = useContext(BackdropCtx);

  const classes = useStyles();

  return (
    <Measure offset onResize={({ offset }) => setTitleHeight(offset.height)}>
      {({ measureRef }) => (
        <RootRef rootRef={measureRef}>
          <Grid
            alignItems="center"
            classes={{ item: classes.titleContainer }}
            container
            direction="column"
            item
          >
            {children}
          </Grid>
        </RootRef>
      )}
    </Measure>
  );
}

const useStyles = makeStyles(
  ({ breakpoints, transitions }) => ({
    titleContainer: {
      flexGrow: 0,
      padding: '16px',
      [breakpoints.up('md')]: {
        padding: '24px 56px',
      },
    },
  }),
  { name: 'BackdropTitle' },
);
