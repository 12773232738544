import { CrqpLogo, AcadLogo } from './logo';

export default [
  {
    id: 1,
    subtitle: 'Leadership et management',
    color: 'yellow',
  },
  {
    id: 2,
    subtitle: 'Ressources humaines',
    color: 'blue',
    logo: CrqpLogo,
  },
  {
    id: 20,
    name: 'e-nnovate',
    subtitle: 'Transformation digitale',
    color: 'greyBlue',
  },
  {
    id: 3,
    subtitle: 'Académie des administrateurs',
    color: 'green',
    logo: AcadLogo,
  },
];
