import gql from 'graphql-tag';

export const getTrainings = gql`
  query trainings {
    trainings @rest(type: "Training", path: "formations.php") {
      id
      courseId
      title
      subtitle
      duration
      price
      forcedPrice
      altPrice
      isNew
      isElearning
      isCertification
      isFree
      certifications
      products {
        id
        name
      }
      keywords
      themes
      themeDescriptors {
        theme {
          id
          name
        }
        level
      }
      targetDescriptors {
        target {
          id
          name
        }
        level
      }
      sessions {
        id
        dates_fr
        duree
        debut_session
      }
    }
  }
`;
