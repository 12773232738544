import React from 'react';

export default function CrqpLogo(props) {
  return (
    <svg viewBox="0 0 434 167" {...props}>
      <defs>
        <path d="M.06.971h433.898V166.94H.06z" id="CRQP-Logo_svg__a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask fill="#fff" id="CRQP-Logo_svg__b">
          <use xlinkHref="#CRQP-Logo_svg__a" />
        </mask>
        <path
          d="M181.753 2.578s-55.4-1.607-67.008 29.45c-18.543-14.115-38.516-.901-38.516-.901 21.65-3.135 36.934 35.707 35.879 87.647-.214 10.536-.277 21.058-2.111 29.986l24.271-10.175c-7.388-21.418-10.553-58.366-6.332-78.176 3.98-18.682 8.442-40.695 53.817-57.831M374.334.971c-24.13.092-44.914 12.685-54.295 30.926-18.389-13.456-37.975-.501-37.975-.501 21.632.535 36.933 35.705 35.878 87.645-.214 10.537-.276 21.058-2.111 29.986l24.271-10.175c-4.402-12.768-9.242-40.955-9.436-58.237-.194-17.28 3.47-62.022 40.767-63.043 30.911-.847 41.136 19.531 41.099 40.91-.035 21.547-19.139 43.457-41.099 41.015-10.911-1.213-19.705-5.694-25.278-9.35-4.262-2.795-6.643-5.108-6.643-5.108 3.694 11.244 23.776 21.642 35.35 23.026 35.879 4.282 59.328-23.913 59.095-54.352C433.732 24.585 413.299.822 374.334.971"
          fill="#5EB245"
          mask="url(#CRQP-Logo_svg__b)"
        />
        <path
          d="M268.857 129.563c13.916-12.822 22.64-31.227 22.64-51.678 0-38.74-31.3-70.146-69.91-70.146s-69.91 31.406-69.91 70.146c0 38.741 31.3 70.146 69.91 70.146 9.752 0 17.622-1.393 27.469-5.624 9.846-4.232 19.801-12.844 19.801-12.844m-24.84 7.893c-5.834 4.281-14.321 7.897-22.43 7.897-26.372 0-47.75-30.205-47.75-67.467 0-37.26 21.378-67.467 47.75-67.467s47.751 30.207 47.751 67.467c0 18.147-5.073 34.619-13.322 46.747 0 0-6.165 8.545-11.999 12.823"
          fill="#5EB245"
          mask="url(#CRQP-Logo_svg__b)"
        />
        <path
          d="M196.217 130.548s34.866-24.291 64.755-.436c6.697 5.345 19.996 17.77 36.814 21.045 16.222 3.162 30.248 3.796 53.24-3.439 0 0-39.741 25.42-71.77 17.885-28.774-6.772-32.028-22.608-46.322-32.969-4.076-2.841-13.234-6.282-36.717-2.086"
          fill="#064A89"
          mask="url(#CRQP-Logo_svg__b)"
        />
        <path
          d="M24.392 65.972c5.677-32.919 34.849-55.882 66.189-54.749a69.183 69.183 0 0 0-9.073-2.278C43.675 2.124 7.65 27.635 1.047 65.924c-6.604 38.29 18.714 74.861 56.549 81.679 14.299 2.578 28.339.535 40.631-5.066a61.569 61.569 0 0 1-21.328-.108c-34.689-6.253-58.711-40.484-52.507-76.457"
          fill="#5EB245"
          mask="url(#CRQP-Logo_svg__b)"
        />
      </g>
    </svg>
  );
}
