import React from 'react';
import PropTypes from 'prop-types';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';

const insertionPoint = document.createElement('style');
insertionPoint.id = 'jss-insertion-point';
document.head.insertBefore(insertionPoint, document.head.firstChild);

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

JSSProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function JSSProvider({ children }) {
  return <StylesProvider jss={jss}>{children}</StylesProvider>;
}
