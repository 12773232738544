import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import color from 'color';

import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  ButtonBase,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

Cell.propTypes = {
  /**
   * The product name displayed when filter is by target
   */
  children: PropTypes.element,
  /**
   * The content of the card
   */
  colorScheme: PropTypes.oneOf([
    'yellow',
    'blue',
    'purple',
    'green',
    'greyBlue',
  ]),
  /**
   * Shadow depth
   */
  elevation: PropTypes.number,
  /**
   * Click handler when user interact with the button
   */
  onClick: PropTypes.func,
  /**
   * The target name displayed when filter is by product
   */
  productName: PropTypes.string.isRequired,
  /**
   * Whether the corner of the card is rounded or not
   */
  square: PropTypes.bool,
  /**
   * The variant used to render the card
   */
  targetName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  /**
   * The color scheme of the card
   */
  variant: PropTypes.oneOf(['default', 'product', 'target']),
};

Cell.defaultProps = {
  children: null,
  colorScheme: 'yellow',
  elevation: 0,
  onClick: () => console.warn('[Cell]: onClick handler is not implemented'),
  square: false,
  variant: 'default',
};

export default function Cell({
  colorScheme,
  children,
  elevation,
  onClick,
  productName,
  square,
  targetName,
  variant,
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      className="table-step-03"
      classes={{
        root: classNames(classes.root, classes[colorScheme]),
      }}
      component={Paper}
      onClick={onClick}
    >
      <div className={classes.titleContainer}>
        {variant !== 'default' && (
          <Typography
            className={classNames(variant === 'product' && 'table-step-02')}
            classes={{ subtitle2: classes.title }}
            color="inherit"
            variant="subtitle2"
          >
            {variant === 'product' ? targetName : productName}
          </Typography>
        )}
        <Typography
          classes={{ subtitle2: classes.subtitle }}
          color="inherit"
          variant="subtitle2"
        >
          {'Vos principaux enjeux'}
        </Typography>
      </div>
      {children && <div className={classes.content}>{children}</div>}
      <Grid classes={{ container: classes.footer }} container justify="center">
        <Grid item>
          <Tooltip position="bottom" title="En savoir plus">
            <IconButton color="inherit">
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}

const useStyles = makeStyles(
  ({ palette, transitions }) => ({
    root: {
      padding: 16,
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      borderRadius: 3,
      boxShadow: 'none',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'black',
        opacity: 0,
        transition: transitions.create('opacity'),
        pointerEvents: 'none',
      },
      '&:hover': {
        '&::after': {
          opacity: 0.08,
        },
      },
    },
    yellow: {
      color: palette.yellow.text,
      backgroundColor: palette.yellow.background,
      backgroundImage: `linear-gradient(top, ${color(palette.yellow.background)
        .alpha(0)
        .rgb()} 0%, ${palette.yellow.background} 100%)`,
    },
    blue: {
      color: palette.blue.text,
      backgroundColor: palette.blue.background,
      backgroundImage: `linear-gradient(top, ${color(palette.blue.background)
        .alpha(0)
        .rgb()} 0%, ${palette.blue.background} 100%)`,
    },
    purple: {
      color: palette.purple.text,
      backgroundColor: palette.purple.background,
      backgroundImage: `linear-gradient(top, ${color(palette.purple.background)
        .alpha(0)
        .rgb()} 0%, ${palette.purple.background} 100%)`,
    },
    green: {
      color: palette.green.text,
      backgroundColor: palette.green.background,
      backgroundImage: `linear-gradient(top, ${color(palette.green.background)
        .alpha(0)
        .rgb()} 0%, ${palette.green.background} 100%)`,
    },
    greyBlue: {
      color: palette.greyBlue.text,
      backgroundColor: palette.greyBlue.background,
      backgroundImage: `linear-gradient(top, ${color(
        palette.greyBlue.background,
      )
        .alpha(0)
        .rgb()} 0%, ${palette.greyBlue.background} 100%)`,
    },
    titleContainer: {
      flex: '0 0 auto',
      alignSelf: 'flex-start',
    },
    title: {
      textTransform: 'uppercase',
      paddingBottom: 16,
    },
    subtitle: {
      paddingBottom: 8,
    },
    content: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      backgroundImage: 'inherit',
      width: '100%',
      '&:after': {
        content: '""',
        backgroundImage: 'inherit',
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: 0,
        height: 32,
      },
    },
    footer: {
      flex: '0 0 auto',
    },
  }),
  { name: 'Cell' },
);
