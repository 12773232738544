import React from 'react';

import { makeStyles } from '@material-ui/styles';
import MuiCssBaseline from '@material-ui/core/CssBaseline';

import '../../assets/avenir-next.css';
import '../../assets/century-gothic.css';

const useStyles = makeStyles(({ palette }) => ({
  '@global': {
    '*': {
      fontFamily: 'AvenirNext',
      '&:focus': {
        outline: 'none',
      },
    },
    html: {
      backgroundColor: '#F0F3F5',
      height: '100%',
      width: '100%',
      /**
       * IE hack
       */
      overflowX: 'hidden',
    },
    body: {
      fontSize: 16,
      height: '100%',
      width: '100%',
      backgroundColor: '#F0F3F5',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    a: {
      color: palette.primary.main,
      textDecoration: 'none',
      fontWeight: 400,
      fontSize: '0.875rem',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

export default function CssBaseline(props) {
  const classes = useStyles();
  return <MuiCssBaseline classes={classes} {...props} />;
}
