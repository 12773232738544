import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import {
  TypographyParagraph,
  TypographyListItem,
  TypographyHeader,
} from './Typography';

MarkdownParser.propTypes = {
  allowedTypes: PropTypes.array,
  disallowedTypes: PropTypes.array,
  source: PropTypes.string,
};

MarkdownParser.defaultProps = {
  allowedTypes: undefined,
  disallowedTypes: undefined,
  source: '',
};

export default function MarkdownParser({
  source,
  allowedTypes,
  disallowedTypes,
}) {
  return (
    <ReactMarkdown
      allowedTypes={allowedTypes}
      disallowedTypes={disallowedTypes}
      renderers={{
        heading: TypographyHeader,
        listItem: TypographyListItem,
        paragraph: TypographyParagraph,
      }}
      source={source}
    />
  );
}
