import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

TypographyHeader.propTypes = {
  children: PropTypes.array.isRequired,
  level: PropTypes.number,
};

TypographyHeader.defaultProps = {
  level: 0,
};

export default function TypographyHeader({ level, children }) {
  let variant;

  switch (level) {
    case 1:
      variant = 'h1';
      break;
    case 2:
      variant = 'h6';
      break;
    case 3:
      variant = 'subtitle1';
      break;
    default:
      variant = 'body2';
      break;
  }

  return (
    <Typography color="inherit" gutterBottom variant={variant}>
      {children}
    </Typography>
  );
}
