import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { Grid } from '@material-ui/core';

import { BackdropCtx } from './Backdrop';

BackdropContent.propTypes = {
  children: PropTypes.element,
};

BackdropContent.defaultProps = {
  children: null,
};

export default function BackdropContent({ children }) {
  const { setContentHeight } = useContext(BackdropCtx);

  return (
    <Measure offset onResize={({ offset }) => setContentHeight(offset.height)}>
      {({ measureRef }) => (
        <Grid item lg={6} md={8} style={{ position: 'relative' }} xl={4}>
          <div ref={measureRef}>{children}</div>
        </Grid>
      )}
    </Measure>
  );
}
