import React from 'react';
import PropTypes from 'prop-types';

import { Input, InputAdornment, IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

FilterField.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

FilterField.defaultProps = {
  value: '',
};

export default function FilterField({ value, onChange, placeholder }) {
  const classes = useStyles();

  return (
    <Input
      classes={classes}
      disableUnderline
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange('')}>
              <Close />
            </IconButton>
          </InputAdornment>
        )
      }
      onChange={event => {
        onChange(event.target.value);
      }}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
      value={value}
    />
  );
}

const useStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 3,
    height: 48,
    width: '100%',
    paddingLeft: 8,
  },
  input: {
    fontSize: 18,
  },
});
