import React from 'react';
import PropTypes from 'prop-types';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Grid, Portal } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import GroupBySwitch from './GroupBySwitch';
import Products from './Products';
import Targets from './Targets';

Table.propTypes = {
  data: PropTypes.object.isRequired,
  displayGroupBy: PropTypes.bool.isRequired,
  group: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onGroupBy: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  targets: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
};

function Table({
  displayGroupBy,
  group,
  onGroupBy,
  history,
  width,
  data,
  products,
  targets,
}) {
  const classes = useStyles();
  return (
    <>
      <Grid classes={{ container: classes.container }} container>
        <Portal>
          <GroupBySwitch
            display={displayGroupBy}
            group={group}
            onGroupBy={onGroupBy}
          />
        </Portal>
        {group === 'product' || isWidthUp('lg', width) ? (
          <Products
            columnClasses={classes.column}
            data={data}
            group={group}
            history={history}
            products={products}
            targets={targets}
            width={width}
          />
        ) : (
          <Targets
            columnClasses={classes.column}
            data={data}
            group={group}
            history={history}
            products={products}
            targets={targets}
            width={width}
          />
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    container: {
      color: '#2A4F62',
      backgroundColor: '#F0F3F5',
      // position: 'relative',
      width: '100%',
      minHeight: '100%',
      padding: '0 0 88px 0',
      [breakpoints.up('sm')]: {
        padding: '0 16px 88px 16px',
      },
      [breakpoints.up('lg')]: {
        padding: '0 32px 32px 32px',
      },
    },
    column: {
      padding: '0 0 16px 0',
      backgroundColor: 'transparent',
      [breakpoints.only('md')]: {
        '&:nth-child(odd)': {
          paddingRight: 16,
        },
      },
      [breakpoints.up('lg')]: {
        backgroundColor: '#CED9DF',
        marginRight: 8,
        padding: 8,
        height: '100%',
      },
      [breakpoints.up('xl')]: {
        padding: 16,
      },
    },
  }),
  { name: 'Table' },
);

export default withWidth()(Table);
