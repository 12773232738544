import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

LoadingOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoadingOverlay.defaultProps = {
  elevation: 2,
  text: '',
};

function LoadingOverlay({ classes, elevation, text }) {
  return (
    <Paper
      classes={{
        root: classes.rootPaper,
      }}
      elevation={elevation}
    >
      <CircularProgress classes={{ root: classes.progress }} />
      <div className={classes.textStyles}>{text}</div>
    </Paper>
  );
}

const styleSheet = ({ palette }) => ({
  rootPaper: {
    padding: 32,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(36, 48, 64, 0.7)',
    color: palette.grey[50],
    zIndex: 99999,
  },
  textStyles: {
    paddingTop: 16,
  },
  progress: {
    color: palette.grey[50],
  },
});

export default withStyles(styleSheet, { name: 'LoadingOverlay' })(
  LoadingOverlay,
);
