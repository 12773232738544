import { Paper, Typography, ButtonBase } from '@material-ui/core';

import { styled } from '@material-ui/styles';

import {
  borders,
  compose,
  css,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@material-ui/system';

export const Box = styled('div')(
  css(
    compose(
      borders,
      display,
      flexbox,
      palette,
      positions,
      shadows,
      sizing,
      spacing,
      typography,
    ),
  ),
);

export const Img = styled('img')(sizing);

export const CardContainer = styled(ButtonBase)(
  compose(
    display,
    palette,
    positions,
    sizing,
    () => ({
      '&:hover': {
        textDecoration: 'none',
      },
    }),
  ),
);

export const CardPaper = styled(Paper)(
  compose(
    palette,
    positions,
    sizing,
    spacing,
  ),
);

export const NextSessionLabel = styled(Typography)(css(typography));

export const Date = styled(Typography)(typography);

export const Title = styled(Typography)(
  css(
    compose(
      palette,
      positions,
      spacing,
    ),
  ),
);

export const Subtitle = styled(Typography)(spacing);

export const FlagContainer = styled('div')(
  compose(
    display,
    flexbox,
  ),
);

export const FlagCircle = styled('div')(
  compose(
    borders,
    palette,
    sizing,
  ),
);

export const FlagText = styled('div')(
  compose(
    positions,
    spacing,
    typography,
  ),
);
