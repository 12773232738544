import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

TypographyParagraph.propTypes = {
  children: PropTypes.array.isRequired,
};

export default function TypographyParagraph({ children }) {
  return (
    <Typography align="justify" color="inherit" paragraph>
      {children}
    </Typography>
  );
}
