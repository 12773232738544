import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isBefore } from 'date-fns';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import JsSearch from './JsSearch';
import ThemeSelector from './ThemeSelector';
import FilterField from './FilterField';
import TrainingGrid from './TrainingGrid';
import OrderSelector from './OrderSelector';

TrainingFilter.propTypes = {
  activateBackButton: PropTypes.func.isRequired,
  display: PropTypes.oneOf(['always', 'result']),
  mustReset: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  productId: PropTypes.number,
  reset: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  trainings: PropTypes.array,
};

TrainingFilter.defaultProps = {
  display: 'result',
  placeholder: '',
  productId: -1,
  targetId: -1,
  trainings: [],
};

const allThemes = [
  ['Management', 1],
  ['Bien-être au travail', 3],
  ['Efficacité personnelle et organisation', 4],
  ['Transformation digitale', 5],
  ['Leadership', 6],
  ['Communication', 7],
  ['Projet', 8],
  ['Ressources humaines', 9],
  ['Administrateur', 10],
  ['Confiance en soi', 11],
  ['Finances', 12],
];

const searchIndexes = ['title', 'themes', 'keywords'];

const activeThemes = {
  '1': [1, 6],
  '2': [9],
  '20': [5, 9],
  '3': [10],
};

export default function TrainingFilter({
  activateBackButton,
  productId,
  targetId,
  display,
  placeholder,
  reset,
  mustReset,
  trainings,
}) {
  const classes = useStyles();

  const [order, setOrder] = useState('title');
  const [themes, updateThemes] = useState([]);

  const [filterValue, filterBy] = useState('');
  const [isActive, activate] = useState(false);
  const hasThemeActive = themes.some(({ active }) => active);

  useEffect(
    () => {
      if (mustReset) {
        filterBy('');
        updateThemes(
          allThemes.reduce(
            (acc, [theme, id]) => [
              ...acc,
              {
                id,
                theme,
                active: false,
              },
            ],
            [],
          ),
        );
        reset(false);
      }
    },
    [mustReset],
  );

  useEffect(
    () => {
      const t = allThemes.reduce(
        (acc, [theme, id]) => [
          ...acc,
          {
            id,
            theme,
            active:
              productId !== -1 && activeThemes[`${productId}`].includes(id),
          },
        ],
        [],
      );
      updateThemes(t);
    },
    [productId],
  );

  useEffect(
    () => {
      if (productId === -1 && !hasThemeActive && filterValue) {
        updateThemes(
          allThemes.reduce(
            (acc, [theme, id]) => [
              ...acc,
              {
                id,
                theme,
                active: true,
              },
            ],
            [],
          ),
        );
      }
    },
    [filterValue],
  );

  useEffect(
    () => {
      if (
        !!filterValue ||
        themes.some(({ active }) => active) ||
        display === 'always'
      ) {
        activate(true);
      } else {
        activate(false);
      }
    },
    [filterValue, themes, display],
  );

  useEffect(
    () => {
      if (isActive) {
        activateBackButton(true);
      } else {
        activateBackButton(false);
      }
    },
    [isActive],
  );

  const sort = (a, b) => {
    if (order === 'date') {
      const aSession = a.sessions[0];
      const bSession = b.sessions[0];
      if (!aSession && !bSession) {
        return 0;
      } else if (!aSession) {
        return 1;
      } else if (!bSession) {
        return -1;
      }

      return isBefore(
        new Date(a.sessions[0].debut_session),
        new Date(b.sessions[0].debut_session),
      )
        ? -1
        : 1;
    }

    return a.title.localeCompare(b.title, 'fr', { ignorePunctuation: true });
  };

  return (
    <>
      <Grid
        alignItems="center"
        className="table-step-04"
        classes={{ container: classes.selectorContainer }}
        container
        justify="center"
      >
        <Grid item md xs={12}>
          <FilterField
            onChange={filterBy}
            placeholder={placeholder}
            value={filterValue}
          />
        </Grid>
        <Grid classes={{ item: classes.buttonContainer }} item md={2} xs={12}>
          <ThemeSelector
            allThemes={allThemes}
            themes={themes}
            updateThemes={updateThemes}
          />
        </Grid>
        {isActive && (
          <Grid classes={{ item: classes.buttonContainer }} item md={2} xs={12}>
            <OrderSelector onChange={setOrder} order={order} />
          </Grid>
        )}
      </Grid>
      <JsSearch
        data={trainings}
        id="id"
        indexes={searchIndexes}
        order={order}
        search={filterValue}
      >
        {filteredData => (
          <TrainingGrid
            active={isActive}
            onUpdateThemes={updateThemes}
            themes={themes}
            trainings={filteredData
              .filter(
                ({ products, targetDescriptors, themeDescriptors, title }) => {
                  return (
                    (productId === -1 ||
                      products.some(({ id }) => {
                        return parseInt(id, 10) === productId;
                      })) &&
                    (targetId === -1 ||
                      targetDescriptors.some(
                        ({ target }) => target.id === targetId,
                      )) &&
                    themeDescriptors.some(({ theme }) =>
                      themes.find(t => t.id === theme.id && t.active),
                    )
                  );
                },
              )
              .sort(sort)}
          />
        )}
      </JsSearch>
    </>
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    selectorContainer: {
      padding: '24px 16px 16px 16px',
      [breakpoints.up('md')]: {
        padding: '40px 32px 32px 32px',
      },
    },
    buttonContainer: {
      marginLeft: 0,
      paddingTop: 16,
      [breakpoints.up('md')]: {
        marginLeft: 16,
        paddingTop: 0,
      },
    },
  }),
  { name: 'TrainingFilter' },
);
