/* eslint-disable graphql/template-strings */
// because of https://github.com/apollographql/eslint-plugin-graphql/issues/99

import gql from 'graphql-tag';

export const typeDefs = `
  type Query {
    getLoading: Info
  }
  type Mutation {
    setLoading($id: ID!, $loading: Boolean): Info
  }
  type Loading {
    loadingComponents: [ID]
  }
`;

export const getLoading = gql`
  query getLoading {
    loading @client {
      loadingComponents
      globalLoading
    }
  }
`;

export const setLoading = gql`
  mutation setLoading($id: ID!, $loading: Boolean) {
    toggleLoading(id: $id, loading: $loading) @client
  }
`;

export default cache => {
  cache.writeData({
    data: {
      loading: {
        loadingComponents: [],
        globalLoading: false,
        __typename: 'Info',
      },
    },
  });

  return {
    resolvers: {
      Mutation: {
        toggleLoading: (_, { id, loading }, { cache }) => {
          const {
            loading: { loadingComponents },
          } = cache.readQuery({ query: getLoading });

          const index = loadingComponents.indexOf(id);
          const inTable = index >= 0;
          const newState = [...loadingComponents];

          if (!inTable && loading) {
            newState.push(id);
          } else if (inTable && !loading) {
            newState.splice(index, 1);
          }

          const data = {
            loading: {
              loadingComponents: newState,
              globalLoading: newState.length > 0,
              __typename: 'Info',
            },
          };

          cache.writeData({ data });
          return data;
        },
      },
    },
  };
};
