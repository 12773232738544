import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Drawer,
  Portal,
  Typography,
  Divider,
} from '@material-ui/core';

import { FilterList, CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

ThemeSelector.propTypes = {
  allThemes: PropTypes.array.isRequired,
  themes: PropTypes.array.isRequired,
  updateThemes: PropTypes.func.isRequired,
};

export default function ThemeSelector({ allThemes, themes, updateThemes }) {
  const [isOpen, open] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Button
        color="primary"
        fullWidth
        onClick={() => open(!isOpen)}
        size="large"
        variant="outlined"
      >
        <FilterList />
        <span style={{ paddingLeft: 16 }}>{'Thèmes'}</span>
      </Button>
      <Portal>
        <Drawer anchor="right" onClose={() => open(false)} open={isOpen}>
          <Typography classes={{ h6: classes.drawerTitle }} variant="h6">
            {'Filter les formations par thèmes'}
          </Typography>
          <Divider />
          <List>
            <ListItem>
              <ListItemText primary="Tous" />
              <ListItemSecondaryAction>
                <Switch
                  checked={themes.every(({ active }) => active)}
                  color="primary"
                  onChange={(_, checked) => {
                    updateThemes(
                      themes.map(theme => ({
                        ...theme,
                        active: checked,
                      })),
                    );
                  }}
                  value={'all'}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {allThemes.map(([themeLabel, themeId]) => (
              <ListItem key={themeId}>
                <ListItemText primary={themeLabel} />
                <ListItemSecondaryAction>
                  <Switch
                    checked={themes.some(
                      ({ id, active }) => id === themeId && active,
                    )}
                    color="primary"
                    onChange={event => {
                      updateThemes(
                        themes.map(theme => ({
                          ...theme,
                          active:
                            theme.id === themeId
                              ? event.target.checked
                              : theme.active,
                        })),
                      );
                    }}
                    value={themeId}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              onClick={() => open(false)}
              variant="contained"
            >
              <CheckCircle className={classes.leftIcon} />
              <span className={classes.label}>{'Valider et fermer'}</span>
            </Button>
          </div>
        </Drawer>
      </Portal>
    </>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  drawerTitle: {
    padding: 16,
  },
  buttonContainer: {
    padding: '48px 16px 16px 16px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  leftIcon: {
    marginRight: spacing(1),
  },
  label: {
    // lineHeight: 'normal',
  },
}));
