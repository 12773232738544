import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import Header from '../../components/Header';
import Table from '../../components/Table';

import TrainingFilter from '../../components/Search/TrainingFilter';

import {
  Backdrop,
  BackdropTitle,
  BackdropContent,
  BackdropFront,
} from '../../components/Backdrop';

import data, { targets, products } from './data';
import { getTrainings } from './gql';
import WalkThrough from './WalkThrough';
import DataProvider from './DataProvider';
import DetailsContent from './DetailsContent';
import DetailsTitle from './DetailsTitle';

export const StaticData = createContext();

Trainings.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default function Trainings({ match: { path }, location, history }) {
  const [group, groupBy] = useState('product');
  const [backButton, activateBackButton] = useState(false);
  const [mustReset, reset] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const classes = useStyles();

  return (
    <WalkThrough location={location}>
      {({ run }) => (
        <StaticData.Provider value={{ data, targets, products }}>
          <Route exact path={path}>
            {({ match }) => (
              <Header
                backButton={backButton}
                basePath={path}
                groupBy={groupBy}
                history={history}
                onStartTour={run}
                reset={reset}
                shadows={match ? 0 : 3}
              />
            )}
          </Route>
          <Route path={`${path}/:productId/:targetId`}>
            {({ match, history, location: { state } }) => {
              let productId =
                (state &&
                  state.params &&
                  parseInt(state.params.productId, 10)) ||
                -1;
              let targetId =
                (state &&
                  state.params &&
                  parseInt(state.params.targetId, 10)) ||
                -1;
              let colorClassName = classes.yellowColor;
              let backgroundClassName = classes.yellowBg;
              let color = 'yellow';

              if (match && match.params && match.params.productId) {
                productId = parseInt(match.params.productId, 10);
              }

              if (match && match.params && match.params.targetId) {
                targetId = parseInt(match.params.targetId, 10);
              }

              const product = products.find(({ id }) => id === productId);

              if (product) {
                colorClassName = classes[`${product.color}Color`];
                backgroundClassName = classes[`${product.color}Bg`];
                color = product.color;
              }

              return (
                <Backdrop
                  canClose={!match}
                  className={classNames(colorClassName, backgroundClassName)}
                  color={color}
                  fixedPosition={!match ? 0 : undefined}
                  initialPosition={match ? 2 : undefined}
                >
                  <BackdropTitle>
                    <DetailsTitle productId={productId} targetId={targetId} />
                  </BackdropTitle>
                  <BackdropContent>
                    <DetailsContent productId={productId} targetId={targetId} />
                  </BackdropContent>
                  <BackdropFront>
                    <DataProvider dataKey="trainings" query={getTrainings}>
                      {trainings => (
                        <TrainingFilter
                          activateBackButton={activateBackButton}
                          display={match ? 'always' : 'result'}
                          mustReset={mustReset}
                          placeholder={
                            match
                              ? 'Filter les formations'
                              : 'Rechercher une formation'
                          }
                          productId={match ? productId : -1}
                          reset={reset}
                          targetId={match ? targetId : -1}
                          trainings={trainings}
                        />
                      )}
                    </DataProvider>
                    {!match && (
                      <Table
                        data={data}
                        displayGroupBy={!backButton}
                        group={group}
                        history={history}
                        location={location}
                        match={match}
                        onGroupBy={groupBy}
                        products={products}
                        targets={targets}
                      />
                    )}
                  </BackdropFront>
                </Backdrop>
              );
            }}
          </Route>
        </StaticData.Provider>
      )}
    </WalkThrough>
  );
}

const useStyles = makeStyles(
  ({ palette }) => ({
    yellowColor: {
      color: palette.yellow.text,
    },
    yellowBg: {
      backgroundColor: palette.yellow.background,
    },
    blueColor: {
      color: palette.blue.text,
    },
    blueBg: {
      backgroundColor: palette.blue.background,
    },
    purpleColor: {
      color: palette.purple.text,
    },
    purpleBg: {
      backgroundColor: palette.purple.background,
    },
    greenColor: {
      color: palette.green.text,
    },
    greenBg: {
      backgroundColor: palette.green.background,
    },
    greyBlueColor: {
      color: palette.greyBlue.text,
    },
    greyBlueBg: {
      backgroundColor: palette.greyBlue.background,
    },
  }),
  { name: 'Trainings' },
);
