import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CellWithData from './CellWithData';

Targets.propTypes = {
  columnClasses: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  targets: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
};

export default function Targets({
  columnClasses,
  data,
  group,
  history,
  products,
  targets,
  width,
}) {
  const classes = useStyles();
  return targets.map(({ title, id: targetId }) => {
    return (
      <Grid
        classes={{ item: classes.targetContainer }}
        item
        key={targetId}
        xs={12}
      >
        <Typography classes={{ h6: classes.targetName }} variant="h6">
          {title.raw}
        </Typography>
        <Grid container item md={12}>
          {products.map(({ id, name, subtitle, color }) => {
            const cellData = data[`${id}-${targetId}`];
            return (
              <Hidden key={`${id}.${targetId}`} mdDown={!cellData}>
                <Grid classes={{ item: columnClasses }} item md={6} xs={12}>
                  <CellWithData
                    data={cellData}
                    group={group}
                    history={history}
                    productColor={color}
                    productId={id}
                    productName={name}
                    productSubtitle={subtitle}
                    square={false}
                    targetId={targetId}
                    targetTitle={title}
                    width={width}
                  />
                </Grid>
              </Hidden>
            );
          })}
        </Grid>
      </Grid>
    );
  });
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    targetContainer: {
      backgroundColor: '#CED9DF',
      padding: '16px 16px 0 16px',
      marginBottom: 16,
      borderRadius: 0,
      '&:nth-child(2)': {
        marginTop: 48,
      },
      [breakpoints.up('sm')]: {
        borderRadius: 3,
      },
    },
    targetName: {
      marginBottom: 32,
    },
  }),
  { name: 'Targets' },
);
