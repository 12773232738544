import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'js-search';
import Snowball from 'snowball';
import { removeStopwords, fr } from 'stopword';

JsSearch.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.array,
  id: PropTypes.string.isRequired,
  indexes: PropTypes.array,
  search: PropTypes.string,
};

JsSearch.defaultProps = {
  data: [],
  indexes: [],
  search: '',
};

let jsSearch;

export default function JsSearch({ children, id, indexes, data, search }) {
  useEffect(
    () => {
      jsSearch = new Search(id);

      const tokenize = text =>
        text
          .split(/[^a-z0-9äâàéèëêïîöôùüûœç]+/i)
          .filter(isDefined => isDefined);

      var stemmer = new Snowball('French');

      const stemmerize = word => {
        stemmer.setCurrent(word);
        stemmer.stem();
        return stemmer.getCurrent();
      };

      /**
       * Convert text into tokens.
       * Each token is then stemmed and passed along with the original word.
       * If only the stem is included in the index, the example below would
       * return nothing.
       *
       * token : communication
       * stem  : commun
       *
       * search input : communica
       *
       */
      jsSearch.tokenizer = {
        tokenize: text =>
          removeStopwords(tokenize(text), fr).reduce(
            (words, word) => [...words, word, stemmerize(word)],
            [],
          ),
      };

      indexes.forEach(index => jsSearch.addIndex(index));
      jsSearch.addDocuments(data);
    },
    [data, indexes],
  );

  return children(search ? jsSearch.search(search) : data);
}
