import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const BackdropCtx = createContext();

Backdrop.propTypes = {
  canClose: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  fixedPosition: PropTypes.number,
  initialPosition: PropTypes.number,
};

Backdrop.defaultProps = {
  canClose: true,
  children: null,
  className: '',
  fixedPosition: -1,
  initialPosition: 0,
};

function Backdrop({
  children,
  color,
  className,
  canClose,
  fixedPosition,
  initialPosition,
}) {
  const classes = useStyles();

  const [titleHeight, setTitleHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  const [positionIndex, setPosition] = useState(0);

  useEffect(
    () => {
      if (initialPosition) {
        setPosition(initialPosition);
      }
    },
    [initialPosition],
  );

  useEffect(
    () => {
      if (!canClose && positionIndex === 0) {
        setPosition(positionIndex + 1);
      }
    },
    [canClose, positionIndex],
  );

  const positions = [-16, titleHeight, titleHeight + contentHeight];

  return (
    <BackdropCtx.Provider
      value={{
        color,
        className,
        setTitleHeight,
        setContentHeight,
        fixedPosition,
        positions,
        positionIndex,
        setPosition,
      }}
    >
      <Grid
        alignItems="center"
        className={classNames(classes.backdrop, className)}
        container
        direction="column"
      >
        {children}
      </Grid>
    </BackdropCtx.Provider>
  );
}

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    backdrop: {
      minHeight: '100%',
      position: 'relative',
      padding: '0 16px',
      [breakpoints.up('md')]: {
        padding: '0 32px',
      },
    },
  }),
  { name: 'Backdrop' },
);

export default withRouter(Backdrop);
