import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import ApolloClientProvider from './ApolloClientProvider';
import theme from './theme';
import JSSProvider from './components/JSSProvider';
import CssBaseline from './components/CssBaseline';
import { ProgressBar } from './components/Loading';
import Trainings from './routes/Trainings';

export default function App() {
  return (
    <JSSProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloClientProvider>
          <ProgressBar />
          <BrowserRouter>
            <Route component={Trainings} path={`/:section?`} />
          </BrowserRouter>
        </ApolloClientProvider>
      </ThemeProvider>
    </JSSProvider>
  );
}
