import React from 'react';
import { Typography } from '@material-ui/core';

export default [
  {
    content: (
      <Typography>
        {
          'Découvrez les principaux enjeux et les défis relatifs à votre sélection.'
        }
      </Typography>
    ),
    title: 'Profils types',
    disableBeacon: true,
    target: '.details-step-01',
  },
  {
    content: (
      <Typography>
        {
          'Parcourer l’ensemble des formations relatives au profil que vous avez choisi.'
        }
      </Typography>
    ),
    title: 'Formations et thèmes',
    disableBeacon: true,
    target: '.details-step-02',
  },
];
