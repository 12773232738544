import React from 'react';
import PropTypes from 'prop-types';

import ciceroLogo from '../../assets/Cicero_Logo_rgb.png';

import Flag from './Flag';

import {
  Box,
  CardContainer,
  CardPaper,
  NextSessionLabel,
  Date,
  Title,
  Subtitle,
  Img,
} from './StyledComponents';

export default function Card({
  certifications,
  colorScheme,
  duration,
  isCertification,
  isElearning,
  isNew,
  link,
  nextDate,
  price,
  subtitle,
  title,
}) {
  const isCicero = certifications.includes('s:9:"is_cicero";s:1:"1"');

  return (
    <CardContainer
      component="a"
      display="block"
      height="100%"
      href={link}
      position="relative"
    >
      <CardPaper
        color={`${colorScheme}.text`}
        height="100%"
        p={4}
        position="relative"
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box flexGrow={1} width={1}>
            <NextSessionLabel
              color="inherit"
              css={{ textTransform: 'uppercase' }}
              fontWeight={600}
              variant="body2"
            >
              {'Prochaine session'}
            </NextSessionLabel>
            <Date color="inherit" fontSize={12} variant="body2">
              {nextDate || 'En cours de planification'}
            </Date>
            <Title
              /**
               * [Awkward hack]
               * Create a full white gradient to override the background color
               * used for the before pseudo component
               */
              bgcolor={`${colorScheme}.background`}
              color="inherit"
              css={{
                backgroundImage:
                  'linear-gradient(to right, #ffffff 0%, #ffffff 100%)',
                cursor: 'pointer',
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  height: '100%',
                  left: -32,
                  width: 8,
                  backgroundColor: 'inherit',
                },
              }}
              mt={4}
              position="relative"
              variant="h5"
            >
              {title.replace(/<br\s*[/]?>/gi, ' - ')}
            </Title>
            <Subtitle
              color="inherit"
              component="div"
              dangerouslySetInnerHTML={{ __html: subtitle }}
              mt={4}
              variant="body2"
            />
          </Box>
          <Box
            alignSelf="flex-end"
            display="flex"
            flex="0 0 auto"
            flexDirection="column"
            width={1}
          >
            {(duration || price) && (
              <Box display="flex" fontSize={12} fontWeight={600} mt={4}>
                {duration && (
                  <Box
                    bgcolor={`${colorScheme}.background`}
                    borderRadius={3}
                    css={{
                      '&:not(:last-child)': {
                        marginRight: 16,
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: duration }}
                    position="relative"
                    px={2}
                    py="4px"
                  />
                )}
                {price && (
                  <Box
                    bgcolor={`${colorScheme}.background`}
                    borderRadius={3}
                    css={{
                      '&:not(:last-child)': {
                        marginRight: 16,
                      },
                    }}
                    position="relative"
                    px={2}
                    py="4px"
                  >
                    {price}
                  </Box>
                )}
              </Box>
            )}
            <Box
              css={{
                '& > :not(:last-child)': {
                  paddingRight: 32,
                },
              }}
              display="flex"
              flexShrink={0}
              height={24}
              justifyContent="flex-start"
              mt={4}
            >
              {isCertification && (
                <Flag bgcolor={`${colorScheme}.background`}>
                  {'Certifiante'}
                </Flag>
              )}
              {isElearning && (
                <Flag bgcolor={`${colorScheme}.background`}>{'Hybride'}</Flag>
              )}
              {isNew && (
                <Flag bgcolor={`${colorScheme}.background`}>{'Nouvauté'}</Flag>
              )}
              {isCicero && (
                <Img
                  alt="Formation certifiée Cicero"
                  height={24}
                  src={ciceroLogo}
                  width={64}
                />
              )}
            </Box>
          </Box>
        </Box>
      </CardPaper>
    </CardContainer>
  );
}

Card.propTypes = {
  certifications: PropTypes.string,
  /**
   * The content of the card
   */
  colorScheme: PropTypes.oneOf([
    'yellow',
    'blue',
    'purple',
    'green',
    'greyBlue',
  ]),
  /**
   * The training duration in plain text
   */
  duration: PropTypes.string.isRequired,
  /**
   * Certification flag for the training
   */
  isCertification: PropTypes.bool,
  /**
   * E-learning flag for the training
   */
  isElearning: PropTypes.bool,
  /**
   * Novelty flag for the training
   */
  isNew: PropTypes.bool,
  /**
   * An external link when needed
   */
  link: PropTypes.string,
  /**
   * The next training date in plain text
   */
  nextDate: PropTypes.string,
  /**
   * Action triggered when button is clicked
   */
  onActionClick: PropTypes.func,
  /**
   * The training price
   */
  price: PropTypes.string,
  /**
   * The training short description
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * The training title
   */
  title: PropTypes.string.isRequired,
};

Card.defaultProps = {
  certifications: '',
  colorScheme: 'yellow',
  isCertification: false,
  isElearning: false,
  isNew: false,
  link: null,
  nextDate: null,
  onActionClick: null,
  price: null,
};
