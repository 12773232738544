import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import DebounceLink from 'apollo-link-debounce';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';

import { fetch } from 'whatwg-fetch';
import { getYear } from 'date-fns';

import createResolvers from './app.state';

const cache = new InMemoryCache({
  addTypename: true,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache,
  resolvers: createResolvers(cache),
  link: ApolloLink.from([
    errorLink,
    new RetryLink(),
    new DebounceLink(100),
    new RestLink({
      uri: 'https://www.crpm.ch/api/',
      fetch,
      typePatcher: {
        Training: (data, _, patchDeeper) => {
          if (data.sessions !== null) {
            const sessions = [];

            for (let i = 0; i < data.sessions.length; i++) {
              if (getYear(new Date(data.sessions[i].debut_session)) < 2050) {
                sessions.push(
                  patchDeeper(data.sessions[i], 'Session', patchDeeper),
                );
              }
            }

            data.sessions = sessions;
          }

          if (data.themeDescriptors) {
            data.themeDescriptors = data.themeDescriptors.map(descriptor =>
              patchDeeper(descriptor, 'ThemeDescriptors', patchDeeper),
            );
          }

          if (data.targetDescriptors) {
            data.targetDescriptors = data.targetDescriptors.map(descriptor =>
              patchDeeper(descriptor, 'TargetDescriptors', patchDeeper),
            );
          }

          if (data.products) {
            data.products = data.products.map(product =>
              patchDeeper(product, 'Product', patchDeeper),
            );
          }

          return data;
        },
        ThemeDescriptors: (data, _, patchDeeper) => {
          if (data.theme !== null) {
            data.theme = patchDeeper(data.theme, 'Theme');
          }
          return data;
        },
        TargetDescriptors: (data, _, patchDeeper) => {
          if (data.target !== null) {
            data.target = patchDeeper(data.target, 'Target');
          }
          return data;
        },
      },
    }),
  ]),
});

ApolloClientProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
};

export default function ApolloClientProvider({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
