import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { MenuItem, ListItemIcon, Typography, Button } from '@material-ui/core';

import { Apps as AppsIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

GoBackButton.propTypes = {
  active: PropTypes.bool.isRequired,
  basePath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  reset: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['icon', 'menu']),
};

GoBackButton.defaultProps = {
  onClick: () => {},
  variant: 'icon',
};

export default function GoBackButton({
  onClick,
  basePath,
  variant,
  active,
  reset,
}) {
  const classes = useStyles();
  return (
    <Route path={`${basePath}/:productId/:targetId`}>
      {({ match, history }) =>
        variant === 'icon' ? (
          <Button
            disabled={!active}
            onClick={() => {
              if (match) {
                history.push('/', {
                  params: {
                    productId: match.params.productId,
                    targetId: match.params.targetId,
                  },
                });
              }
              reset(true);
            }}
            variant="text"
          >
            <AppsIcon className={classes.leftIcon} />
            <span className={classes.label}>{'Retour au tableau'}</span>
          </Button>
        ) : (
          <MenuItem
            disabled={!active}
            onClick={() => {
              if (match) {
                history.push('/', {
                  params: {
                    productId: match.params.productId,
                    targetId: match.params.targetId,
                  },
                });
              }
              onClick();
              reset(true);
            }}
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Retour au tableau'}</Typography>
          </MenuItem>
        )
      }
    </Route>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  leftIcon: {
    marginRight: spacing(1),
  },
  label: {
    lineHeight: 'normal',
  },
}));
