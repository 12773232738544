import React from 'react';
import PropTypes from 'prop-types';

import { isWidthUp } from '@material-ui/core/withWidth';

import Cell from '../../components/Cell';
import MarkdownParser from '../../components/MarkdownParser';

CellWithData.propTypes = {
  data: PropTypes.array,
  group: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  productColor: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  productSubtitle: PropTypes.string.isRequired,
  square: PropTypes.bool.isRequired,
  targetId: PropTypes.number.isRequired,
  targetTitle: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

CellWithData.defaultProps = {
  data: null,
};

export default function CellWithData({
  data,
  productSubtitle,
  productColor,
  productId,
  targetId,
  targetTitle,
  history,
  width,
  group,
  square,
}) {
  return (
    data && (
      <Cell
        colorScheme={productColor}
        onClick={() => history.push(`/details/${productId}/${targetId}`)}
        productName={productSubtitle}
        square={square}
        targetName={targetTitle.raw}
        variant={isWidthUp('lg', width) ? 'default' : group}
      >
        <MarkdownParser
          source={
            data[1].split('\n').length
              ? `${data[1].split('\n')[0]}\n${data[1].split('\n')[1]}`
              : ''
          }
        />
      </Cell>
    )
  );
}
