/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { setLoading } from './loading.resolver';

class Loading extends PureComponent {
  static propTypes = {
    /**
     * The function used to render when the component has been loaded
     */
    children: PropTypes.func.isRequired,
    /**
     * The Apollo client provided by withApollo
     */
    client: PropTypes.object.isRequired,
    /**
     * Differentiate each component in the local cache.
     */
    id: PropTypes.string.isRequired,
    /**
     * If true, then the component is loading.
     * If you're loading an Apollo Query, you probably
     * want to use the queryResult prop instead of this one.
     */
    loading: PropTypes.bool,
    /**
     * Can be used to render a specific component during loading operation
     */
    placeholder: PropTypes.node,
    /**
     * An Apollo Query result object.
     *
     * This props is required by the "cache during loading" process
     * when you have functionalities inside your child function that
     * update the data object returned by an Apollo query (aka fetchMore).
     * It ensure that child function always use the correct object.
     *
     * Instead of using the result from the Query render prop in your children,
     * you use the object passed to this component render prop function.
     */
    queryResult: PropTypes.object,
    /**
     * Define the behavior during loading phases
     *
     * cache : use a cached version of the previous child tree
     * placeholder : always display the placeholder component
     */
    variant: PropTypes.oneOf(['cache', 'placeholder']),
  };

  static defaultProps = {
    loading: false,
    placeholder: null,
    queryResult: null,
    variant: 'cache',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const loading = nextProps.queryResult
      ? nextProps.queryResult.loading
      : nextProps.loading;

    if (prevState.loading === undefined) {
      /**
       * We initialize state on initial render
       * but without setting the cached children because we
       * haven't rendered it yet.
       */
      return {
        loading,
        children: nextProps.children,
        queryResult: nextProps.queryResult,
      };
    } else if (prevState.loading && !loading) {
      /**
       * We keep track of the current child tree when
       * the component enter in a stable state
       */
      return {
        loading,
        hasRender: true,
        children: nextProps.children,
        queryResult: nextProps.queryResult,
      };
    } else if (!prevState.loading && loading) {
      /**
       * On subsequent loading update, we rely on previous state
       * to create a cached version of the children
       */
      return {
        loading,
        cachedChildren: prevState.children(prevState.queryResult),
      };
    }

    return null;
  }

  state = {
    cachedChildren: null,
    hasRender: false,
    loading: undefined,
  };

  componentDidMount() {
    const { loading } = this.state;
    this.mutate(loading);
  }

  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.state;
    this.mutate(loading);
  }

  componentWillUnmount() {
    this.mutate(false);
  }

  mutate = loading => {
    const { client, id } = this.props;
    client.mutate({
      mutation: setLoading,
      variables: { id, loading },
    });
  };

  render() {
    const { placeholder, children, variant, queryResult } = this.props;
    const { cachedChildren, hasRender, loading } = this.state;

    let renderedWhileLoading = cachedChildren;

    if (!hasRender || variant === 'placeholder') {
      renderedWhileLoading = placeholder;
    }

    return loading ? renderedWhileLoading : children(queryResult);
  }
}

export default withApollo(Loading);
