import overrideTypography from './MuiTypography';
import overrideTooltip from './MuiTooltip';

const overrides = [overrideTypography, overrideTooltip];

export default palette => {
  return overrides.reduce(
    (obj, override) => ({ ...obj, ...override(palette) }),
    {},
  );
};
