import React from 'react';
import PropTypes from 'prop-types';

import { FlagContainer, FlagCircle, FlagText } from './StyledComponents';

export default function Flag({ children, bgcolor }) {
  return (
    <FlagContainer alignItems="center" display="flex">
      <FlagText fontSize={12} mr={'-20px'} zIndex={1}>
        {children}
      </FlagText>
      <FlagCircle bgcolor={bgcolor} borderRadius={12} height={24} width={24} />
    </FlagContainer>
  );
}

Flag.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  children: PropTypes.any,
};

Flag.defaultProps = {
  children: null,
};
