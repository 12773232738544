import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Card from '../Card';

import { StaticData } from '../../routes/Trainings';

GridSection.propTypes = {
  activeThemes: PropTypes.array,
  displayMeta: PropTypes.bool,
  displayTitle: PropTypes.bool,
  onUpdateThemes: PropTypes.func,
  productId: PropTypes.number.isRequired,
  themes: PropTypes.array,
  trainings: PropTypes.array,
};

GridSection.defaultProps = {
  activeThemes: [],
  displayMeta: false,
  displayTitle: false,
  onUpdateThemes: () => {},
  themes: [],
  trainings: [],
};

export default function GridSection({
  activeThemes,
  trainings,
  productId,
  displayMeta,
  displayTitle,
  themes,
  onUpdateThemes,
}) {
  const { products } = useContext(StaticData);
  const classes = useStyles();

  return (
    <Grid classes={{ container: classes.cardContainer }} container item xs={12}>
      {displayMeta && (
        <Grid classes={{ item: classes.meta }} item xs={12}>
          <Typography component="div" variant="body1">
            {`${trainings.length} formation${
              trainings.length > 1 ? 's' : ''
            } trouvée${trainings.length > 1 ? 's' : ''} pour le${
              activeThemes.length > 1 ? 's' : ''
            } thème${activeThemes.length > 1 ? 's' : ''} `}
            {activeThemes.map(({ theme, id }) => (
              <Chip
                className={classes.chip}
                key={id}
                label={theme}
                onDelete={() => {
                  onUpdateThemes(
                    themes.map(theme => ({
                      ...theme,
                      active: theme.id === id ? false : theme.active,
                    })),
                  );
                }}
              />
            ))}
          </Typography>
        </Grid>
      )}
      {displayTitle && (
        <Grid classes={{ item: classes.meta }} item xs={12}>
          <Typography classes={{ h5: classes.moreTitle }} variant="h5">
            {`${
              trainings.length === 1
                ? 'Cette formation pourrait'
                : 'Ces formations pourraient'
            } également vous intéresser`}
          </Typography>
        </Grid>
      )}
      {trainings.map(training => {
        const currentProductId = training.products[0]
          ? parseInt(training.products[0].id, 10)
          : 1;
        const fallbackProductId =
          productId !== -1 ? productId : currentProductId;

        return (
          <Grid
            classes={{ item: classes.cardItem }}
            item
            key={training.id}
            lg={4}
            md={6}
            xl={2}
            xs={12}
          >
            <Card
              certifications={training.certifications}
              colorScheme={
                products.find(({ id }) => id === fallbackProductId).color
              }
              duration={training.duration}
              isCertification={!!parseInt(training.isCertification, 10)}
              isElearning={!!parseInt(training.isElearning, 10)}
              isFree={!!parseInt(training.isFree, 10)}
              isNew={!!parseInt(training.isNew, 10)}
              link={`https://www.crpm.ch/formations.php/${training.id}`}
              nextDate={training.sessions[0] && training.sessions[0].dates_fr}
              price={training.price && `${training.price}.-`}
              subtitle={training.subtitle}
              title={training.title}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  meta: {
    paddingBottom: 32,
  },
  moreTitle: {
    paddingTop: 56,
    paddingBottom: 24,
  },
  cardContainer: {
    maxWidth: '100%',
    marginRight: 0,
    width: 'auto',
    [breakpoints.up('md')]: {
      width: 'calc(100vw + 16px)',
      marginRight: -16,
      flexBasis: 'auto',
      maxWidth: 'unset',
    },
  },
  cardItem: {
    paddingBottom: 16,
    paddingRight: 0,
    [breakpoints.up('md')]: {
      paddingRight: 16,
    },
  },
  chip: {
    margin: spacing(1),
    backgroundColor: 'rgba(61, 85, 97, 0.08)',
    color: palette.text.primary,
  },
}));
