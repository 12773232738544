export default ({ palette: { text } }) => ({
  MuiTypography: {
    h1: {
      color: text.primary,
      fontSize: 52,
      fontWeight: 600,
    },
    h2: {
      color: text.primary,
    },
    h3: {
      color: text.primary,
    },
    h4: {
      color: text.primary,
    },
    h5: {
      color: text.primary,
    },
    h6: {
      color: text.primary,
    },
    subtitle1: {
      color: text.primary,
    },
    body1: {
      color: text.primary,
    },
    body2: {
      color: text.primary,
    },
    caption: {
      color: text.primary,
    },
    button: {
      color: text.primary,
    },
  },
});
