import React from 'react';

export default function SvgAcadLogo(props) {
  return (
    <svg viewBox="0 0 532 193" {...props}>
      <defs>
        <path d="M.06.763h531.461V144.94H.06z" id="ACAD-Logo_svg__a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M272.073 75.536c-10.039-8.926-23.801-13.761-37-13.761-31.426 0-55.414 26.222-55.414 57.273 0 30.68 23.8 56.898 55.039 56.898 13.761 0 26.961-5.207 37.375-14.5v20.633c-10.785 6.324-24.547 9.86-36.817 9.86-39.976 0-73.074-31.797-73.074-72.332 0-41.465 33.281-73.821 74.563-73.821 12.273 0 24.726 2.977 35.328 9.485v20.265M346.685 81.114l-24.918 56.715h49.836l-24.918-56.715zm-32.168 72.707l-15.992 35.141h-18.781L346.685 42.81l66.941 146.152h-18.781l-15.992-35.141h-64.336zM116.254 122.712L84.098 49.521l-32.156 73.191-9.356 20.641-20.64 45.351-21.637-4.367L84.098.087l86.391 188.617H146.25l-20.64-45.351-9.356-20.641"
          fill="#636463"
        />
        <g transform="translate(0 48)">
          <mask fill="#fff" id="ACAD-Logo_svg__b">
            <use xlinkHref="#ACAD-Logo_svg__a" />
          </mask>
          <path
            d="M441.341 124.97h6.137c35.703 0 66.566-13.387 66.566-54.106 0-40.91-30.863-54.113-66.566-54.113h-6.137V124.97zM423.861.763h29.008c42.953 0 78.652 22.683 78.652 69.355 0 47.789-34.957 70.844-79.207 70.844h-28.453V.763z"
            fill="#636463"
            mask="url(#ACAD-Logo_svg__b)"
          />
          <path
            d="M23.873 145s22.531-66.667 214.671-75.838c0 0-198.196-16.657-238.544 67.149L23.873 145"
            fill="#79BB43"
            mask="url(#ACAD-Logo_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
}
