import raw from 'raw.macro';

export { default as targets } from './targets';
export { default as products } from './products';

export default {
  '1-2': [raw('./1-2/0.md'), raw('./1-2/1.md'), raw('./1-2/2.md')],
  '1-3': [raw('./1-3/0.md'), raw('./1-3/1.md'), raw('./1-3/2.md')],
  '1-4': [raw('./1-4/0.md'), raw('./1-4/1.md'), raw('./1-4/2.md')],
  '1-5': [raw('./1-5/0.md'), raw('./1-5/1.md'), raw('./1-5/2.md')],
  // '1-6': ['', '', ''],
  '2-2': [raw('./2-2/0.md'), raw('./2-2/1.md'), raw('./2-2/2.md')],
  '2-3': [raw('./2-3/0.md'), raw('./2-3/1.md'), raw('./2-3/2.md')],
  '2-4': [raw('./2-4/0.md'), raw('./2-4/1.md'), raw('./2-4/2.md')],
  '2-5': [raw('./2-5/0.md'), raw('./2-5/1.md'), raw('./2-5/2.md')],
  // '2-6': ['', '', ''],
  // '3-2': ['', '', ''],
  // '3-3': ['', '', ''],
  // '3-4': ['', '', ''],
  // '3-5': ['', '', ''],
  '3-6': ['', raw('./3-6/1.md'), ''],
  '20-2': ['', raw('./20-2/1.md')],
  '20-3': ['', raw('./20-3/1.md')],
  '20-4': ['', raw('./20-4/1.md')],
  '20-5': ['', raw('./20-5/1.md')],
  // '20-6': ['', '', ''],
};
