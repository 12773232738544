import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import classNames from 'classnames';
import { css } from 'emotion';

import { Grid, Button, Tooltip } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';

import { BackdropCtx } from './Backdrop';

BackdropFront.propTypes = {
  children: PropTypes.node,
};

BackdropFront.defaultProps = {
  children: null,
};

export default function BackdropFront({ children }) {
  const { palette } = useTheme();
  const classes = useStyles();

  const {
    color,
    fixedPosition,
    positions,
    positionIndex,
    setPosition,
  } = useContext(BackdropCtx);

  const buttonStyles = css`
    // border-color: ${palette[color].background};
    // color: ${palette[color].text};
    // border-radius: 20px;
  `;

  return (
    <Spring
      from={{ y: -16 }}
      native
      to={{
        y: positions[fixedPosition !== -1 ? fixedPosition : positionIndex],
      }}
    >
      {({ y }) => {
        return (
          <animated.div
            className={classes.content}
            style={{
              transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`),
            }}
          >
            {fixedPosition === -1 && (
              <Grid container justify="center">
                <Grid classes={{ item: classes.unfoldContainer }} item>
                  <Tooltip
                    title={
                      positionIndex === positions.length - 1
                        ? 'Réduire le volet'
                        : 'En savoir plus'
                    }
                  >
                    <Button
                      className={classNames(buttonStyles)}
                      color="primary"
                      onClick={() =>
                        setPosition(
                          positionIndex < positions.length - 1
                            ? positionIndex + 1
                            : 0,
                        )
                      }
                      variant="text"
                    >
                      <KeyboardArrowDown
                        classes={{
                          root: classNames(
                            classes.unfold,
                            positionIndex === positions.length - 1 &&
                              classes.unfoldOpen,
                          ),
                        }}
                      />
                      <span style={{ padding: '0 16px' }}>
                        {positionIndex === positions.length - 1
                          ? 'Fermer'
                          : 'Voir les informations'}
                      </span>
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
            {children}
          </animated.div>
        );
      }}
    </Spring>
  );
}

const useStyles = makeStyles(
  ({ breakpoints, shadows, transitions }) => ({
    content: {
      borderRadius: '16px 16px 0 0',
      position: 'absolute',
      backgroundColor: '#F0F3F5',
      boxShadow: shadows[8],
      top: 0,
      right: 0,
      left: 0,
      minHeight: '100%',
    },
    unfoldContainer: {
      marginTop: 16,
      [breakpoints.up('md')]: {
        marginTop: 32,
      },
    },
    unfold: {
      transition: transitions.create('transform'),
      transform: 'rotate(0)',
    },
    unfoldOpen: {
      transform: 'rotate(180deg)',
    },
  }),
  { name: 'BackdropFront' },
);
