import React from 'react';

import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Portal from '@material-ui/core/Portal';

import { Query } from 'react-apollo';

import { getLoading } from './loading.resolver';

export default function ProgressBar() {
  const classes = useStyles();

  return (
    <Query query={getLoading}>
      {({ data }) =>
        data.loading.globalLoading && (
          <Portal>
            <LinearProgress
              classes={{ root: classes.progressRoot }}
              color="primary"
            />
          </Portal>
        )
      }
    </Query>
  );
}

const useStyles = makeStyles(
  {
    progressRoot: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 9999,
      backgroundColor: 'transparent',
    },
  },
  { name: 'ProgressBar' },
);
