import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import SwipeableViews from 'react-swipeable-views';

import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import MarkdownParser from '../../components/MarkdownParser';

import { StaticData } from './';

DetailsContent.propTypes = {
  productId: PropTypes.number.isRequired,
  targetId: PropTypes.number.isRequired,
};

export default function DetailsContent({ productId, targetId }) {
  if (productId === -1 || targetId === -1) {
    return null;
  }

  const classes = useStyles();
  const { data, products } = useContext(StaticData);
  const [tab, setTab] = useState(1);
  const [desc, stakes, abilities] = data[`${productId}-${targetId}`];
  const { color } = products.find(({ id }) => id === productId);

  return (
    <>
      <Tabs
        classes={{
          root: classes.tabsRoot,
          indicator:
            classes[
              `indicator${color.charAt(0).toUpperCase() + color.slice(1)}`
            ],
        }}
        onChange={(_, value) => setTab(value)}
        textColor="inherit"
        value={tab}
        variant="fullWidth"
      >
        <Tab label="Description" />
        <Tab label="Principaux enjeux" />
        <Tab label="Compétences clés" />
      </Tabs>
      <SwipeableViews index={tab} onChangeIndex={value => setTab(value)}>
        <div className={classes.description}>
          <MarkdownParser source={desc} />
        </div>
        <MarkdownParser source={stakes} />
        <MarkdownParser source={abilities} />
      </SwipeableViews>
    </>
  );
}

const useStyles = makeStyles(
  ({ palette }) => ({
    description: {
      marginTop: 16,
      width: '100%',
    },
    tabsRoot: {
      width: '100%',
    },
    indicatorYellow: {
      backgroundColor: palette.yellow.text,
    },
    indicatorBlue: {
      backgroundColor: palette.blue.text,
    },
    indicatorPurple: {
      backgroundColor: palette.purple.text,
    },
    indicatorGreen: {
      backgroundColor: palette.green.text,
    },
    indicatorGreyBlue: {
      backgroundColor: palette.greyBlue.text,
    },
  }),
  { name: 'DetailsContent' },
);
