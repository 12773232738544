import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Grid, Switch, Hidden } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

GroupBySwitch.propTypes = {
  display: PropTypes.bool.isRequired,
  group: PropTypes.string.isRequired,
  onGroupBy: PropTypes.func.isRequired,
};

export default function GroupBySwitch({ onGroupBy, group, display }) {
  const classes = useStyles();

  return (
    <Hidden lgDown={!display} lgUp>
      <Grid
        alignItems="center"
        classes={{ container: classes.selector }}
        container
        justify="center"
      >
        <Grid alignItems="center" container item justify="center">
          <Typography>{'Regrouper par fonctions'}</Typography>
          <Switch
            checked={group === 'product'}
            color="primary"
            onChange={(_, checked) => onGroupBy(checked ? 'product' : 'target')}
            value="group"
          />
          <Typography>{'produits'}</Typography>
        </Grid>
      </Grid>
    </Hidden>
  );
}

const useStyles = makeStyles(
  ({ shadows }) => ({
    selector: {
      flexShrink: 0,
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      height: 72,
      backgroundColor: '#F0F3F5',
      boxShadow: shadows[8],
      zIndex: 99,
    },
    groupBy: {
      marginTop: 32,
    },
  }),
  { name: 'GroupBySwitch' },
);
