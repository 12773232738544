import React from 'react';
import PropTypes from 'prop-types';
import color from 'color';

import {
  Select,
  MenuItem,
  InputBase,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { styled, withStyles } from '@material-ui/styles';

OrderSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.string,
};

OrderSelector.defaultProps = {
  order: 'title',
};

const StyledFormControl = styled(FormControl)(({ theme: { breakpoints } }) => ({
  [breakpoints.up('md')]: {
    marginTop: -24,
  },
}));

const CustomInput = withStyles(({ palette, transitions, spacing }) => ({
  root: {
    'label + &': {
      marginTop: spacing(3),
    },
  },
  input: {
    color: palette.primary.main,
    borderRadius: 4,
    position: 'relative',
    border: `1px solid ${color(palette.primary.main).fade(0.5)}`,
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      border: `1px solid ${palette.primary.main}`,
    },
  },
}))(InputBase);

export default function OrderSelector({ order, onChange }) {
  return (
    <StyledFormControl fullWidth>
      <InputLabel>{'Trier par'}</InputLabel>
      <Select
        fullWidth
        input={<CustomInput />}
        onChange={event => onChange(event.target.value)}
        value={order}
        variant="outlined"
      >
        <MenuItem value="title">{'Nom des formations'}</MenuItem>
        <MenuItem value="date">{'Date de la prochaine session'}</MenuItem>
      </Select>
    </StyledFormControl>
  );
}
