import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

Title.propTypes = {
  /**
   * Color scheme of the card
   */
  colorScheme: PropTypes.oneOf([
    'yellow',
    'blue',
    'purple',
    'green',
    'greyBlue',
  ]),
  /**
   * The product logo to display
   */
  logo: PropTypes.func,
  /**
   * The product name to display
   */
  name: PropTypes.string,
  /**
   * The content of the card
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Define the size of the title component
   */
  variant: PropTypes.oneOf(['default', 'large']),
};

Title.defaultProps = {
  colorScheme: 'yellow',
  logo: null,
  name: '',
  subtitle: '',
  variant: 'default',
};

export default function Title({
  colorScheme,
  name,
  logo: Logo,
  subtitle,
  variant,
}) {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.container,
        classes[colorScheme],
        variant === 'large' && classes.containerLarge,
      )}
    >
      {/* <Typography
        classes={{
          h4: classNames(classes.h4, variant === 'large' && classes.h4Large),
        }}
        color="inherit"
        variant="h4"
      >
        {product}
      </Typography> */}
      <Typography
        classes={{
          h5: classNames(classes.h5, variant === 'large' && classes.h5Large),
        }}
        color="inherit"
        variant="h5"
      >
        {subtitle}
      </Typography>
      {Logo && <Logo className={classes.logo} height={27} width={75} />}
      {name && (
        <Typography
          classes={{
            h5: classes.productName,
          }}
          color="inherit"
          variant="h5"
        >
          {name}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles(
  ({ palette, breakpoints }) => ({
    container: {
      position: 'relative',
      zIndex: 2,
      lineBreak: 'strict',
      marginLeft: 32,
      [breakpoints.up('sm')]: {
        marginLeft: 0,
      },
      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        borderRadius: 3,
        top: -16,
        left: 128,
        width: 128,
        height: 128,
        zIndex: -1,
      },
    },
    containerLarge: {
      '&::before': {
        width: 176,
        height: 176,
      },
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h4Large: {
      fontSize: 52,
    },
    h5Large: {
      fontSize: 32,
    },
    yellow: {
      color: palette.yellow.text,
      '&::before': {
        backgroundColor: palette.yellow.background,
      },
    },
    blue: {
      color: palette.blue.text,
      '&::before': {
        backgroundColor: palette.blue.background,
      },
    },
    purple: {
      color: palette.purple.text,
      '&::before': {
        backgroundColor: palette.purple.background,
      },
    },
    green: {
      color: palette.green.text,
      '&::before': {
        backgroundColor: palette.green.background,
      },
    },
    greyBlue: {
      color: palette.greyBlue.text,
      '&::before': {
        backgroundColor: palette.greyBlue.background,
      },
    },
    logo: {
      marginTop: 16,
    },
    productName: {
      marginTop: 8,
    },
  }),
  { name: 'Title' },
);
