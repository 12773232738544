import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { Typography } from '@material-ui/core';

TypographyListItem.propTypes = {
  children: PropTypes.array.isRequired,
};

export default function TypographyListItem({ children }) {
  return (
    <li
      className={css`
        margin-left: -20px;
      `}
    >
      <Typography color="inherit" component="span">
        {children}
      </Typography>
    </li>
  );
}
